import * as actionTypes from '../actions/actionTypes';

interface IActionPayload {
    type: string,
    payload: object
};

export default (state = {}, action:IActionPayload) => {
    switch (action.type) {
        case actionTypes.UPDATE_CARDS:
            return action.payload;
        default:
            return state;
    }
};
