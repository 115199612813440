import * as actionTypes from '../actions/actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case actionTypes.INIT_UI:
        case actionTypes.REMOVE_AMAZONPAY_SESSION:
            return Object.assign({}, state, action.payload);
        case `${actionTypes.INIT_BOOKING_SUMMARY_ITEMS}_RESOLVED`:
            return Object.assign({}, state, {
                summaryInitialised: true,
                isLoggedIn: action.payload.member_logged_id
            });
        default:
            return state;
    }
};
