import * as actionTypes from '../actions/actionTypes';
import User from '../../../common/models/User';

export default (state = new User(), action) => {
    switch (action.type) {
        case actionTypes.INIT_USER_DETAILS:
        case actionTypes.UPDATE_AMAZONPAY_USER:
            return Object.assign(new User(), state, action.payload);
        default:
            return state;
    }
};
