export const INIT_PRODUCT_ITEMS = 'INIT_PRODUCT_ITEMS';
export const INIT_BOOKING_SUMMARY_ITEMS = 'INIT_BOOKING_SUMMARY_ITEMS';
export const REMOVE_BOOKING_SUMMARY_ITEM = 'REMOVE_BOOKING_SUMMARY_ITEM';
export const FIND_POSTCODE = 'FIND_POSTCODE';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const INIT_TOTALS_SUMMARY = 'INIT_TOTALS_SUMMARY';
export const INIT_UPDATE_TOTAL = 'INIT_UPDATE_TOTAL';
export const INIT_USER_DETAILS = 'INIT_USER_DETAILS';
export const INIT_UI = 'INIT_UI';
export const ADD_GIFT_VOUCHER = 'ADD_GIFT_VOUCHER';
export const ADD_GIFT_VOUCHER_INITIAL = 'ADD_GIFT_VOUCHER_INITIAL';
export const REMOVE_VOUCHER = 'REMOVE_VOUCHER';
export const INIT_UPDATE_LOYALTY = 'INIT_UPDATE_LOYALTY';
export const REMOVE_LOYALTY = 'REMOVE_LOYALTY';
export const INIT_CSRF_TOKEN = 'INIT_CSRF_TOKEN';
export const INIT_AMAZONPAY_DATA = 'INIT_AMAZONPAY_DATA';
export const REMOVE_AMAZONPAY_SESSION = 'REMOVE_AMAZONPAY_SESSION';
export const UPDATE_AMAZONPAY_USER = 'UPDATE_AMAZONPAY_USER';
export const INIT_COUNTRY_LIST = 'INIT_COUNTRY_LIST';
export const UPDATE_TOTALS_LOYALTY = 'UPDATE_TOTALS_LOYALTY';
export const UPDATE_TOTALS_VOUCHERS = 'UPDATE_TOTALS_VOUCHERS';
export const UPDATE_NIGHTS = 'UPDATE_NIGHTS';
export const UPDATE_TOTALS_SHIPPING = 'UPDATE_TOTALS_SHIPPING';
export const INIT_CONFIRM_ORDER = 'INIT_CONFIRM_ORDER';
export const INIT_CONFIRM_PAYMENT = 'INIT_CONFIRM_PAYMENT';
export const INIT_ORDER_ADDRESS = 'INIT_ORDER_ADDRESS';
export const UPDATE_CARDS = 'UPDATE_CARDS';
export const INIT_UPDATE_FORM_ERRORS = 'INIT_UPDATE_FORM_ERRORS';
export const INIT_GLOBAL_ERRORS = 'INIT_GLOBAL_ERRORS';
export const SHIPPING_OPTIONS = 'SHIPPING_OPTIONS';
export const GET_TERMS_CONTENT = 'GET_TERMS_CONTENT';
export const ADD_SHIPPING = 'ADD_SHIPPING';
export const REMOVE_SHIPPING = 'REMOVE_SHIPPING';
export const INIT_UPDATE_PREPAY = 'INIT_UPDATE_PREPAY';
export const INIT_LINEITEM_LENGTH = 'INIT_LINEITEM_LENGTH';
export const INIT_CARD_SELECTION = 'INIT_CARD_SELECTION';
export const INIT_SHIPPING = 'INIT_SHIPPING';
export const CONFIRM_WITH_APPLEPAY = 'CONFIRM_WITH_APPLEPAY';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const UPDATE_FORMDATA = 'UPDATE_FORMDATA';
export const UPDATE_ADDRESSOPEN = 'UPDATE_ADDRESSOPEN';
export const INIT_DISCOUNT_LABEL = 'INIT_DISCOUNT_LABEL';
export const INIT_MINIMUM_CHARGE = 'INIT_MINIMUM_CHARGE';
export const INIT_STRIPE_PAYMENT_ELEMENT = 'INIT_STRIPE_PAYMENT_ELEMENT';
