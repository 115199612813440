import React, { Component } from 'react';
import DataCaptureForm from './DataCaptureForm';
import SubmitFormConfirmation from './SubmitFormConfirmation';
import withFormValidation from '../../common/hocs/withFormValidation';
import * as validationService from '../../area/booking/services/validationService';


const FormWithValidation = withFormValidation(DataCaptureForm, validationService);

export default class DataCaptureFormContainer extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            successfullSubmission: false,
            confirmation: {},
            shouldSendPost: false
        };

        this.setConfirmation = this.setConfirmation.bind(this);
        this.setSendPost = this.setSendPost.bind(this);
    }

    setConfirmation(confirmationResponse) {
        const { confirmation } = this.state;
        this.setState({
            confirmation: Object.assign({}, confirmation, confirmationResponse),
            successfullSubmission: true
        });
    }

    setSendPost() {
        this.setState({ shouldSendPost: true });
    }

    render() {
        const { successfullSubmission, confirmation, shouldSendPost } = this.state;
        const { formEndpoint, smithNewsletter, closeModal } = this.props;

        return (
            <div>
                { successfullSubmission ?
                    <SubmitFormConfirmation
                        confirmation={confirmation}
                        close={closeModal}
                        shouldSendPost={shouldSendPost}
                        smithNewsletter={smithNewsletter}
                    /> :
                    <FormWithValidation
                        setConfirmation={this.setConfirmation}
                        formEndpoint={formEndpoint}
                        setSendPost={this.setSendPost}
                        smithNewsletter={smithNewsletter}
                    />
                }
            </div>
        );
    }
}
