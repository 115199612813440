import React from 'react';
import PropTypes from 'prop-types';
import InputTypes from '../../../common/components/InputTypes';
import { SelectBox } from '../../../common/components/SelectBox';

const AddressFields = ({
    formPrefix,
    userAddress,
    countries,
    stateList,
    onChange,
    selectChange,
    onBlur,
    errors,
    inputRefs,
    addressRef
}) => {
    const {
        [`${formPrefix}name`]: name,
        [`${formPrefix}address1`]: address1,
        [`${formPrefix}address2`]: address2,
        [`${formPrefix}city`]: city,
        [`${formPrefix}postcode`]: postcode,
        [`${formPrefix}country`]: countryCode,
        [`${formPrefix}state`]: stateCode,
    } = userAddress;

    const postcodeLabel = ['US', 'CA'].indexOf(countryCode) !== -1 ? 'Zip code' : 'Postcode';

    return (
        <div ref={addressRef}>
            { formPrefix === 'shipping'
            && (
                <InputTypes
                    inputRef={inputRefs[`${formPrefix}name`]}
                    name={`${formPrefix}name`}
                    label="Name"
                    onChange={onChange}
                    placeHolder="Name"
                    value={name}
                    isRequired={true}
                    onBlur={onBlur}
                    error={errors[`${formPrefix}name`]}
                    autocomplete="none"
                />
            )
            }
            <div className="p-address">
                <InputTypes
                    inputRef={inputRefs[`${formPrefix}address1`]}
                    name={`${formPrefix}address1`}
                    label="Address line 1"
                    onChange={onChange}
                    placeHolder="Address line 1"
                    value={address1}
                    isRequired={true}
                    onBlur={onBlur}
                    error={errors[`${formPrefix}address1`]}
                    autocomplete={`${formPrefix} address-line1`}
                />
            </div>
            <div className="p-address">
                <InputTypes
                    name={`${formPrefix}address2`}
                    label="Address line 2"
                    onChange={onChange}
                    placeHolder="Address line 2"
                    value={address2}
                    onBlur={onBlur}
                    error={errors[`${formPrefix}address2`]}
                    autocomplete={`${formPrefix} address-line2`}
                />
            </div>
            <div className="p-city">
                <InputTypes
                    inputRef={inputRefs[`${formPrefix}city`]}
                    name={`${formPrefix}city`}
                    label="City"
                    onChange={onChange}
                    placeHolder="City"
                    isRequired={true}
                    value={city}
                    onBlur={onBlur}
                    error={errors[`${formPrefix}city`]}
                    autocomplete={`${formPrefix} address-level2`}
                />
            </div>
            <div className="p-postcode">
                <InputTypes
                    inputRef={inputRefs[`${formPrefix}postcode`]}
                    name={`${formPrefix}postcode`}
                    label="Postcode"
                    onChange={onChange}
                    placeHolder={postcodeLabel}
                    isRequired={true}
                    value={postcode}
                    onBlur={onBlur}
                    error={errors[`${formPrefix}postcode`]}
                    autocomplete={`${formPrefix} postal-code`}
                />
            </div>

            { countries.length > 0
                ? (
                    <div className="country-select">
                        <SelectBox
                            name={`${formPrefix}country`}
                            options={countries}
                            onChange={selectChange}
                            valueIndex={0}
                            value={countryCode}
                            placeholder="false"
                            error={errors[`${formPrefix}country`]}
                            inputRef={inputRefs[`${formPrefix}country`]}
                            autocomplete={`${formPrefix} country`}
                        />
                    </div>
                ) : null
            }
            { stateList.length > 0
                ? (
                    <div className="p-state-select">
                        <SelectBox
                            name={`${formPrefix}state`}
                            options={stateList}
                            valueIndex={0}
                            onChange={selectChange}
                            value={stateCode}
                            placeholder="State"
                            error={errors[`${formPrefix}state`]}
                            inputRef={inputRefs[`${formPrefix}state`]}
                            autocomplete={`${formPrefix} address-level1`}
                            isRequired={true}
                        />
                    </div>
                )
                : null
            }
        </div>
    );
};

AddressFields.propTypes = {
    formPrefix: PropTypes.string.isRequired,
    userAddress: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    stateList: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    selectChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func
};

AddressFields.defaultProps = {
    stateList: [],
    onBlur: () => {},
    addressRef: () => {}
};

export default AddressFields;
