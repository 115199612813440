import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import { createLogger } from 'redux-logger';
import { bookingSummaryItems, products, longestNight, getTermsContent } from '../area/booking/reducers/booking';
import totals from '../area/booking/reducers/totalSummary';
import userDetails from '../area/booking/reducers/userDetails';
import ui from '../area/booking/reducers/ui';
import vouchers from '../area/booking/reducers/vouchers';
import requestTokens from '../area/booking/reducers/requestTokens';
import countries from '../area/booking/reducers/countries';
import orderAddresses from '../area/booking/reducers/orderAddress';
import cardTypes from '../area/booking/reducers/cardTypes';
import formErrors from '../area/booking/reducers/formErrors';
import shippingOptions from '../area/booking/reducers/shippingOptions';
import { amazonPay } from '../area/booking/reducers/amazonPay';
import formData from '../area/booking/reducers/formData';
import { addressOpenFlag } from '../area/booking/reducers/orderAddress';
import getFromDataLayer from '../utilities/getFromDataLayer';
import stripePaymentElement from '../area/booking/reducers/stripePaymentElement';

// import { rootReducer } from '../area/booking'; // This fails in unit tests???

const rootReducer = combineReducers({
    bookingSummaryItems,
    products,
    longestNight,
    totals,
    userDetails,
    ui,
    formData,
    vouchers,
    requestTokens,
    stripePaymentElement,
    amazonPay,
    countries,
    orderAddresses,
    cardTypes,
    formErrors,
    shippingOptions,
    addressOpenFlag,
    getTermsContent
});

const middlewares = [];

middlewares.push(thunk);

const forceLogin = getFromDataLayer(window.dataLayer, 'hasForceLogin');
const isUserLoggedIn = getFromDataLayer(window.dataLayer, 'user').loggedIn;
const userMembership = getFromDataLayer(window.dataLayer, 'user').membershipCategory;
const userSessionCountry = getFromDataLayer(window.dataLayer, 'user').sessionCountry;
const siteTag = getFromDataLayer(window.dataLayer, 'siteTag');

const setOfferCheckBox = ['US', 'AR'].includes(userSessionCountry) && !isUserLoggedIn;

if (process.env.NODE_ENV !== 'production') {
    const loggerMiddleware = createLogger();
    middlewares.push(loggerMiddleware);
}

const initialState = {
    bookingSummaryItems: [],
    ui: {
        hasForceLogin: forceLogin,
        isLoggedIn: isUserLoggedIn,
        siteTag,
        userMembership,
        hasReturnedFromPayment: false,
        requiresPaymentProviderRedirect: false
    },
    formData: {
        common: {
            offerCheckbox: setOfferCheckBox ? 1 : 0
        }
    }
};

export const store = createStore(
    enableBatching(rootReducer),
    initialState,
    applyMiddleware(...middlewares)
);
