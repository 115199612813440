import Address from '../../../common/models/Address';

const uuidv1 = require('uuid/v1');

interface IAddressLookup {
    posttown: string, 
    county: string, 
    postcode: string,
    organisation?: string,
    buildingname?: string,
    subbuildingname?: string,
    number: string,
    street: string
}

export default (
    ad: IAddressLookup 
) => {
    const id = uuidv1();
    let address1;
    let address2;
    const city = `${ad.posttown || ''}`;
    const county: string = ad.county || '';
    const postcode: string = ad.postcode || '';
    const state: string = ad.county || '';

    if (ad.organisation && ad.buildingname) {
        address1 = `${ad.organisation}, ${ad.buildingname}`;
    } else if (ad.organisation) {
        address1 = `${ad.organisation}`;
    } else if (ad.subbuildingname && ad.buildingname) {
        address1 = `${ad.subbuildingname}, ${ad.buildingname}`;
    } else if (ad.subbuildingname) {
        address1 = `${ad.subbuildingname}`;
    } else if (ad.buildingname) {
        address1 = `${ad.buildingname}`;
    } else if (ad.number) {
        address1 = `${ad.number} ${ad.street}`;
    } else if (ad.street) {
        address1 = `${ad.street}`;
    } else {
        address1 = '';
    }

    if (ad.number && ad.street) {
        address2 = `${ad.number} ${ad.street}`;
    } else if (ad.number) {
        address2 = `${ad.number}`;
    } else if (ad.street) {
        address2 = `${ad.street}`;
    } else {
        address2 = '';
    }

    return new Address(id, address1, address2, county, city, postcode, state);
};
