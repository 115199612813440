import React from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

const renderButtons = (buttons, closeHandler) => buttons.map((button) => {
    const id = v4();
    if (button.type === 'anchor') {
        return (
            <a target="_blank" key={id} rel="noopener noreferrer" href={button.url}>{button.text}</a>
        );
    }
    if (button.type === 'button' && button.url) {
        return (
            <div className="dataCapture__ctas" key={id}>
                <a rel="noopener noreferrer" className="c-dataCapture__cta c-dataCapture__cta--confirmation" href={button.url}>{button.text}</a>
            </div>
        );
    }
    return (
        <div className="dataCapture__ctas" key={id}>
            <button className="c-dataCapture__cta c-dataCapture__cta--confirmation" onClick={(e) => { e.preventDefault(); closeHandler(); }}>{button.text}</button>
        </div>
    );
});

const SubmitFormConfirmation = ({ confirmation, close, shouldSendPost, smithNewsletter }) => {
    const { buttons } = confirmation;
    const titleClass = smithNewsletter ? 'c-dataCapture__confirmationTitle c-dataCapture__confirmationTitle--newsletter' : 'c-dataCapture__confirmationTitle';

    return (
        <article className="c-dataCapture clearfix">
            <header className="c-dataCapture__header">
                <img className="c-dataCapture__backgroundImg" src={confirmation.imageUrl} alt="" />
                { confirmation.imageUrl2 && (
                    <img className="c-dataCapture__backgroundImg" src={confirmation.imageUrl2} alt="" />
                )}
            </header>
            <section className="c-dataCapture__content">
                <h2 className={titleClass} dangerouslySetInnerHTML={{ __html: confirmation.title }} />

                {shouldSendPost &&
                    <p className="c-dataCapture_copy" dangerouslySetInnerHTML={{ __html: confirmation.post }} />
                }
                <p className="c-dataCapture_copy" dangerouslySetInnerHTML={{ __html: confirmation.copy }} />
                { buttons && (
                    renderButtons(buttons, close)
                )}
            </section>
        </article>
    );
};

SubmitFormConfirmation.propTypes = {
    confirmation: PropTypes.object.isRequired,
    close: PropTypes.func,
    shouldSendPost: PropTypes.bool.isRequired,
    smithNewsletter: PropTypes.bool
};

SubmitFormConfirmation.defaultProps = {
    close: () => {},
    smithNewsletter: false
};

export default SubmitFormConfirmation;
