import * as actionTypes from '../actions/actionTypes';
import arrangeCountries from '../../../utilities/arrangeCountries';

export default (state = [], action) => {
    switch (action.type) {
        case actionTypes.INIT_COUNTRY_LIST:
            return [...arrangeCountries(action.payload)];
        default:
            return state;
    }
};
