import { combineReducers } from 'redux';
import * as actionTypes from '../actions/actionTypes';

const common = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_FORMDATA:
            return Object.assign({}, state, action.formData);
        default:
            return state;
    }
};

const card = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PAYMENT:
            return Object.assign({}, state, action.card);
        default:
            return state;
    }
};

export default combineReducers({
    common,
    card
});
