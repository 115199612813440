import $ from 'jquery';
import React from 'react';
import { render } from 'react-dom';
import slick from 'slick-carousel'; // eslint-disable-line no-unused-vars
import Modal from '../common/components/modal/Modal';
import DataCaptureFormContainer from '../components/dataCaptureForm/DataCaptureFormContainer';
import LoginContainer from '../area/login/components/LoginContainer';
import getFromDataLayer from '../utilities/getFromDataLayer';
import VoucherActivationForm from '../components/VoucherActivationForm';

const modalFormRoot = document.createElement('div');
modalFormRoot.setAttribute('id', 'modalForm');

document.getElementById('main-section').appendChild(modalFormRoot);

const ledAstrayFormTrigger = document.querySelector('a[href="#ledastrayDM"]');
if (ledAstrayFormTrigger) {
    ledAstrayFormTrigger
        .addEventListener('click', (event) => {
            event.preventDefault();
            render(
                <Modal show={true}>
                    <DataCaptureFormContainer formEndpoint="/form/subscription/ledastraydm" />
                </Modal>,
                document.getElementById('modalForm')
            );

            window.scrollTo(0, 0);
        });
}


const competitionFormTrigger = document.querySelector('a[href="#honeymoonpdf"]');
if (competitionFormTrigger) {
    competitionFormTrigger
        .addEventListener('click', (event) => {
            event.preventDefault();
            render(
                <Modal show={true}>
                    <DataCaptureFormContainer formEndpoint="/form/subscription/honeymoon" />
                </Modal>,
                document.getElementById('modalForm')
            );

            window.scrollTo(0, 0);
        });
}

const investorFormTrigger = document.querySelector('a[href="#investor"]');

if (investorFormTrigger) {
    investorFormTrigger
        .addEventListener('click', (event) => {
            event.preventDefault();
            render(
                <Modal show={true}>
                    <DataCaptureFormContainer formEndpoint="/form/subscription/investor" />
                </Modal>,
                document.getElementById('modalForm')
            );

            window.scrollTo(0, 0);
        });
}

const futureLabsReportFormTrigger = document.querySelector('a[href="#futurelabsreport"]');

if (futureLabsReportFormTrigger) {
    futureLabsReportFormTrigger
        .addEventListener('click', (event) => {
            event.preventDefault();
            render(
                <Modal show={true}>
                    <DataCaptureFormContainer formEndpoint="/form/subscription/futurelabsreport" />
                </Modal>,
                document.getElementById('modalForm')
            );

            window.scrollTo(0, 0);
        });
}

const nhsFormTrigger = document.querySelector('a[href="#nhsform"]');

if (nhsFormTrigger) {
    nhsFormTrigger
        .addEventListener('click', (event) => {
            event.preventDefault();
            render(
                <Modal show={true}>
                    <DataCaptureFormContainer formEndpoint="/form/subscription/nhs" />
                </Modal>,
                document.getElementById('modalForm')
            );
            window.scrollTo(0, 0);
        });
}

const loadCarousel = () => {
    const clusterPage = document.querySelectorAll('.j-initialising-carousel');

    [...clusterPage].map((item) => {

        if (item.children.length > 1) {
            $(item).slick({
                infinite: true,
                slidesToScroll: 1,
                swipeToSlide: true,
                swipe: true,
                dots: true,
                prevArrow: '<div class="prev"></div>',
                nextArrow: '<div class="next"></div>'
            });
        }
    });
};


const user = getFromDataLayer(window.dataLayer, 'user');
const voucherContainer = document.getElementById('voucherActivationContainer');
const voucherButtons = document.querySelectorAll('#voucherActivationContainer .c-ui-button');
let signInButton;
let joinButton;

if (voucherButtons) {
    [signInButton, joinButton] = voucherButtons;
}


if (signInButton && joinButton) {
    const currentLocation = window.location.href;
    signInButton
        .addEventListener('click', (event) => {
            event.preventDefault();
            render(
                <Modal show={true}>
                    <LoginContainer onSuccessAction="reload" socialReturn={currentLocation} returnLink={currentLocation} />
                </Modal>,
                document.getElementById('modalForm')
            );
        });

    joinButton
        .addEventListener('click', (event) => {
            event.preventDefault();
            render(
                <Modal show={true}>
                    <LoginContainer onSuccessAction="reload" socialReturn={currentLocation} returnLink={currentLocation} type="signup" />
                </Modal>,
                document.getElementById('modalForm')
            );
        });
}

if (voucherContainer && user.loggedIn) {
    const formTitle = voucherContainer.getAttribute('data-activation');
    render(
        <VoucherActivationForm formTitle={formTitle} />,
        voucherContainer
    );
}

window.addEventListener('load', () => {
    loadCarousel();
});


const $toggleCTA = $('#toggleButton');

if ($toggleCTA) {
    const $contentShowHide = $('#toggleContent');

    const toggleIconClass = () => {
        $toggleCTA.toggleClass('seo-intro__link--iconUp');
    }

    $toggleCTA.slideToggleContent($contentShowHide, 0, 500, 'swing', { more: 'Read more', less: 'Read less' });
    $toggleCTA.on('click', toggleIconClass);
}


// temp - Remove when FAQ can be setup in Sonata
const accordion = () => {
    // This id is from the sonata content, we need to target the parent so no other components are affeted
    $('#cluster-faqs').parent().addClass('c-textBox__content--faqs');

    // We removed the classes from the sonata content as we have a character cap
    $('#cluster-faqs p').addClass('c-loyaltyFaqs__listText');

    $('#cluster-faqs li').on('click', (event) => {
        $(event.currentTarget).toggleClass('c-loyaltyFaqs__listItem--open');
    });
};

window.addEventListener('load', () => {
    accordion();
});
