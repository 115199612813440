import { combineReducers } from 'redux';
import * as actionTypes from '../actions/actionTypes';

const createUpdateAmountReducer = amountType => (state = {}, action) => {
    if (action.amountType !== amountType) {
        return state;
    }

    switch (action.type) {
        case actionTypes.INIT_TOTALS_SUMMARY:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
};

const loyalty = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.INIT_UPDATE_LOYALTY:
            return Object.assign({}, state, {
                amount: action.payload.amount,
                amountWithSymbol: action.payload.amountWithSymbol,
                remaining: action.payload.remaining
            });
        default:
            return state;
    }
};

const forcePrepay = (state = false, action) => {
    switch (action.type) {
        case actionTypes.INIT_UPDATE_PREPAY:
            return action.payload;
        default:
            return state;
    }
};

const hasLineItems = (state = 0, action) => {
    switch (action.type) {
        case actionTypes.INIT_LINEITEM_LENGTH:
            return action.payload;
        default:
            return state;
    }
};

const discountLabel = (state = 'Discount', action) => {
    switch (action.type) {
        case actionTypes.INIT_DISCOUNT_LABEL:
            return action.payload;
        default:
            return state;
    }
};

const minimumCharge = (state = 0, action) => {
    switch (action.type) {
        case actionTypes.INIT_MINIMUM_CHARGE:
            return action.payload;
        default:
            return state;
    }
};

const shippingMethod = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.ADD_SHIPPING:
            return Object.assign({}, state, action.payload.booking.shipping, {
                id: action.id
            });
        case actionTypes.REMOVE_SHIPPING:
            return Object.assign({});
        default:
            return state;
    }
};

export default combineReducers({
    amount: createUpdateAmountReducer('amount'),
    amountWithSymbol: createUpdateAmountReducer('amountWithSymbol'),
    forcePrepay,
    loyalty,
    hasLineItems,
    shippingMethod,
    discountLabel,
    minimumCharge,
});
