import { combineReducers } from 'redux';
import * as actionTypes from '../actions/actionTypes';

const createUpdateAddressReducer = addressType => (state = {}, action) => {
    if (action.addressType !== addressType) {
        return state;
    }
    switch (action.type) {
        case actionTypes.INIT_ORDER_ADDRESS:
            return Object.assign({}, state, action.address);
        default:
            return state;
    }
};

export const addressOpenFlag = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ADDRESSOPEN:
            return Object.assign({}, state, action.addressData);
        default:
            return state;
    }
};

export default combineReducers({
    orderBillingAddress: createUpdateAddressReducer('billing'),
    orderDeliveryAddress: createUpdateAddressReducer('shipping')
});
