import * as actionTypes from '../actions/actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case actionTypes.INIT_STRIPE_PAYMENT_ELEMENT:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
};
