import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputTypes from '../../../common/components/InputTypes';
import { SelectBox } from '../../../common/components/SelectBox';
import api from '../services/api';
import inputWithSubmitButton from '../../../common/hocs/withSubmitButton';

const SubmitInput = inputWithSubmitButton(InputTypes);

export default class PostCodeLookup extends Component {
    /**
     * propTypes
     * 
     * @property {string} country postcode lookup needs country code
     *
     */

    static get propTypes() {
        return {
            country: PropTypes.string.isRequired,
            enterManually: PropTypes.func.isRequired,
            countries: PropTypes.array.isRequired
        };
    }

    static defaultProps = {
        country: 'GB',
        enterManually: () => {},
        isRequired: false
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            postCodeInput: '',
            postCodeLookupResponse: '',
            selectedAddress: '',
            lookupCountry: '',
            isProcessing: false
        };

        this.handlePostCodeInputChange = this.handlePostCodeInputChange.bind(this);
        this.doPostCodeLookup = this.doPostCodeLookup.bind(this);
    }

    componentWillUnmount() {
        const { updateErrors } = this.props;
        updateErrors({
            postCodeLookup: ''
        });
    }

    /**
     * Calls the postcode lookpu api to get address list,
     * which is then stored in the state
     */
    doPostCodeLookup(event) {
        event.preventDefault();
        const { country } = this.props;
        const { postCodeInput } = this.state;

        this.setState({ isProcessing: true });

        api.getPostCodeLookup(postCodeInput, country)
            .then(addressList => this.setState({
                postCodeLookupResponse: addressList,
                hasLookupAddress: true,
                isProcessing: false
            }, () => { this.resultsBox.focus(); }))
            .catch((errMessage) => {
                this.setState({
                    hasLookupAddress: false,
                    lookupError: errMessage,
                    isProcessing: false
                }
                );
            });
    }

    /**
     * Updates the state to the value of the postcode entered in the input
     * Sets the state to the postcode input
     * 
     * @param {object} event get the input value 
     */
    handlePostCodeInputChange(event) {
        this.setState({
            postCodeInput: event.target.value
        });
    }


    renderResults() {
        const { postCodeLookupResponse } = this.state;
        const { onChange, name } = this.props;

        if (postCodeLookupResponse.length > 0) {
            const addressSummaryArray = postCodeLookupResponse.reduce((acc, curr) => {
                acc.push([JSON.stringify(curr), curr.summaryline]);
                return acc;
            }, []);
            return (
                <div className="billing-select">
                    <SelectBox
                        inputRef={(input) => { this.resultsBox = input; }}
                        name={name}
                        valueIndex={0}
                        options={addressSummaryArray}
                        onChange={onChange}
                        placeholder="Select your address"
                    />
                </div>
            );
        }
    }

    renderPostCodeInput() {
        const { postCodeInput, isProcessing } = this.state;
        const { validate, errors, country, inputRefs, isRequired } = this.props;
        const isDisabled = !postCodeInput;
        const buttonClass = isProcessing ? 'postcode-button processing' : 'postcode-button';

        const postcodeLabel = ['US', 'CA'].indexOf(country) !== -1 ? 'Search by zip code' : 'Search by postcode';

        const inputProps = {
            onChange: this.handlePostCodeInputChange,
            name: 'postCodeLookup',
            label: postcodeLabel,
            placeHolder: postcodeLabel,
            value: postCodeInput,
            isRequired,
            onBlur: validate,
            error: errors.postCodeLookup,
            inputRef: inputRefs.postCodeLookup,
            autocomplete: 'postal-code'
        };

        const buttonProps = {
            onclick: this.doPostCodeLookup,
            classes: buttonClass,
            value: 'Search',
            disabled: isDisabled
        };

        return (
            <SubmitInput
                inputProps={inputProps}
                buttonProps={buttonProps}
            />
        );
    }

    /**
     * Render react element
     * 
     * @return {ReactElement}
     */
    render() {
        const { postCodeInput, hasLookupAddress, lookupError } = this.state;
        const { countries, onCountrySelection, countrySelectName, country, enterAddressManually } = this.props;

        return (
            <div>
                <div className="country-select">
                    <SelectBox
                        name={countrySelectName}
                        value={country}
                        valueIndex={0}
                        options={countries}
                        onChange={onCountrySelection}
                        placeholder={'false'}
                    />
                </div>
                <div className="flex-column-s flex-row-top-justify-m flex-row-top-justify-l">
                    <div className="postcode-lookup">
                        { this.renderPostCodeInput() }
                    </div>
                    <div className="postcode-results">
                        { hasLookupAddress &&
                            this.renderResults()
                        }
                        <a className="summary-link clearfix" onClick={enterAddressManually}>Enter address manually</a>
                    </div>
                </div>
                { !hasLookupAddress && postCodeInput &&
                    <div className="c-form__error">{lookupError}</div>
                }
            </div>
        );
    }
}

