import { store } from '../app/reduxStore';

const dispatch = (action, actionSuffix = '', data) => {
    if (action) {
        store.dispatch({
            type: `${action}${actionSuffix}`,
            ...data
        });
    }
};

const doRequest = (action, url, request) =>
    new Promise((resolve, reject) => {
        dispatch(action, '_PENDING');
        fetch(url, request)
            .then((response) => {
                if (response.ok) {
                    response.json().then((payload) => {
                        dispatch(action, '_RESOLVED', { payload });
                        resolve(payload);
                    });
                } else {
                    response.json().then((payload) => {
                        dispatch(action, '_REJECTED', { payload });
                        reject(payload);
                    });
                }
            }).catch((error) => {
                dispatch(action, '_REJECTED', { error });
                reject(error);
            });
    });

export default {
    get(action, endpoint, fetchOptions = {}) {
        const request = Object.assign({}, { method: 'GET' }, fetchOptions);
        const fetchRequest = new Request(endpoint, request);

        return doRequest(action, endpoint, fetchRequest);
    },

    post(action, endpoint, fetchOptions = {}) {
        const request = Object.assign({}, { method: 'POST' }, fetchOptions);

        return doRequest(action, endpoint, request);
    }
};
