import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputTypes from '../common/components/InputTypes';
import withFormValidation from '../common/hocs/withFormValidation';
import * as validationService from '../area/booking/services/validationService';
import api from '../common/services/api';

export class VoucherActivationForm extends Component {
    static get propTypes() {
        return {
            validateForm: PropTypes.func,
            onBlurValidation: PropTypes.func,
            formErrors: PropTypes.object
        };
    }

    static defaultProps = {
        validateForm: () => {},
        onBlurValidation: () => {},
        formErrors: {}
    }

    constructor(props) {
        super(props);

        this.state = {
            wsbVoucherCode: '',
            shouldRemindVoucherExpiry: false,
            apiResponseError: ''
        };
        this.submitForm = this.submitForm.bind(this);
        this.updateFormData = this.updateFormData.bind(this);
    }

    updateFormData(event) {
        const { name, value, checked } = event.target;

        if (name === 'wsbVoucherCode') {
            this.setState({ wsbVoucherCode: value });
        } else {
            this.setState({ shouldRemindVoucherExpiry: checked });
        }
    }

    submitForm(event) {
        event.preventDefault();
        const { validateForm } = this.props;

        const { wsbVoucherCode, shouldRemindVoucherExpiry } = this.state;

        validateForm(event.target.form).then((errors) => {
            const numberOfErrors = Object.keys(errors).reduce((acc, curr) => {
                if (errors[curr] !== '') {
                    acc += 1;
                }
                return acc;
            }, 0);

            const formData = Object.create(null);
            formData['activate_voucher[code]'] = wsbVoucherCode;
            if (shouldRemindVoucherExpiry) {
                formData['activate_voucher[notifications]'] = 1;
            }

            if (numberOfErrors === 0) {
                api.validateVoucher(formData).then((response) => {
                    window.location.assign(response.redirect);
                }).catch((error) => {
                    this.setState({ apiResponseError: error.message });
                });
            }
        });
    }

    render() {
        const { wsbVoucherCode, shouldRemindVoucherExpiry, apiResponseError } = this.state;
        const { onBlurValidation, formErrors, formTitle } = this.props;
        return (
            <form className="clearfix">
                <span className="voucherActivationTitle">{formTitle}</span>
                <div className="voucherInput">
                    <InputTypes
                        name="wsbVoucherCode"
                        label="Voucher"
                        value={wsbVoucherCode}
                        onChange={this.updateFormData}
                        layout="floating"
                        isRequired={true}
                        onBlur={onBlurValidation}
                        error={formErrors.wsbVoucherCode}
                    />
                </div>
                { apiResponseError && <span className="c-form__error">{apiResponseError}</span> }
                <InputTypes
                    type="checkbox"
                    name="shouldRemindVoucherExpiry"
                    checked={shouldRemindVoucherExpiry}
                    onChange={this.updateFormData}
                    label="Remind me before it expires"
                    layout="inverted"
                />
                <button className="activateVoucherCta" type="button" onClick={this.submitForm}>
                    Activate
                </button>
            </form>
        );
    }
}

export default withFormValidation(VoucherActivationForm, validationService);
