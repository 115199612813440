export default class Address {

    id: number;
    address1: string;
    address2: string;
    county: string;
    city: string;
    postcode: string;
    state: string;
    region?: string;
    country?: string;

    constructor(
        id: number,
        address1: string,
        address2: string,
        county: string,
        city: string,
        postcode: string,
        state: string,
        region?: string,
        country?: string
    ) {
        this.id = id;
        this.address1 = address1;
        this.address2 = address2;
        this.county = county;
        this.city = city;
        this.postcode = postcode;
        this.state = state;
        this.region = region;
        this.country = country;
    }
}
