import httpClient from '../../../utilities/httpClient';
import endpoints from '../../../common/constants/endpoints';
import * as bookingActionTypes from '../actions/actionTypes';
import { ALLIES_POSTCODE_LOOKUP } from '../../../common/constants/thirdPartyJs';
import { buildFormToPost } from '../modelBuilders/formModelBuilder';
import objectToParms from '../../../utilities/objectToParams';
import getFromDataLayer from '../../../utilities/getFromDataLayer';
import router from '../../../app/backend_router';

const siteTag = getFromDataLayer(window.dataLayer, 'siteTag');
const smithLocale = getFromDataLayer(window.dataLayer, 'smithLocale');

export default {
    getBookingSummary(params = {}) {
        const routingParameters = Object.assign({}, params, {
            site_tag: siteTag,
            _smith_locale: smithLocale
        });

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });
        const fetchOptions = {
            credentials: 'include',
            headers
        };

        return httpClient.get(bookingActionTypes.INIT_BOOKING_SUMMARY_ITEMS,
            router.generate(endpoints.getBookingSummary, routingParameters), fetchOptions)
            .then(res => res)
            .catch((error) => {
                console.log(error);
                window.location.assign(document.referrer);
            });
    },

    getBookingSummaryPi(paymentIntentId, currency, reissue = false) {
        const routingParameters = {
            site_tag: siteTag,
            _smith_locale: smithLocale,
            paymentIntent: paymentIntentId,
            currency,
            reissue
        };

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });
        const fetchOptions = {
            credentials: 'include',
            headers,
        };

        return httpClient.get(bookingActionTypes.INIT_BOOKING_SUMMARY_ITEMS,
            router.generate(endpoints.getBookingSummaryPi, routingParameters), fetchOptions)
            .then(res => res)
            .catch((error) => {
                console.log(error);
            });
    },

    addVoucher(code, token, nights = 0, country = 'GB', paymentIntentId) {
        const postBody = `&code=${encodeURIComponent(code)}&_token=${encodeURIComponent(token)}&payment_intent=${encodeURIComponent(paymentIntentId)}`;

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });
        const fetchOptions = {
            credentials: 'include',
            headers,
            body: postBody
        };

        return httpClient.post(bookingActionTypes.ADD_GIFT_VOUCHER,
            router.generate(endpoints.addVoucher, {
                site_tag: siteTag,
                _smith_locale: smithLocale,
                number_of_nights: nights,
                country
            }), fetchOptions).then(res => res);
    },

    getPostCodeLookup(postcode, country) {
        if (!postcode || country.length > 2) {
            return Promise.reject();
        }
        const mapped = country === 'GB' ? 'UK' : country;
        return httpClient.get(bookingActionTypes.FIND_POSTCODE,
            `${ALLIES_POSTCODE_LOOKUP}/address/${mapped}/${postcode}`, {})
            .then(
                (res) => {
                    if (!res.length) {
                        return Promise.reject('No addresses found');
                    }
                    return res;
                }
            );
    },

    removeBookingSummaryItem(removeLink, paymentIntentId = '') {
        const postBody = `&payment_intent=${encodeURIComponent(paymentIntentId)}`;

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });

        const fetchOptions = {
            credentials: 'include',
            headers,
            method: 'POST',
            body: postBody
        };

        return httpClient.post(bookingActionTypes.REMOVE_BOOKING_SUMMARY_ITEM, removeLink, fetchOptions)
            .then(res => res);
    },

    createPaymentIntent(currencyCode, amountToPay) {
        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });

        const postBody = buildFormToPost({
            currency: currencyCode,
            amount: amountToPay,
            use_automatic_payment_methods: true
        });

        const fetchOptions = {
            credentials: 'include',
            method: 'POST',
            headers,
            body: postBody
        };

        const url = router.generate(endpoints.createPaymentIntent);

        return fetch(`${url}`, fetchOptions)
            .then(response => response.json());
    },

    confirmPaymentIntent(currencyCode, paymentIntentReference, userEmail, paymentMethodId = null, returnUrl = '') {
        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });

        const postBody = buildFormToPost({
            currency: currencyCode, email: userEmail, paymentMethodId, returnUrl
        });

        const fetchOptions = {
            credentials: 'include',
            method: 'POST',
            headers,
            body: postBody
        };

        const url = router.generate(endpoints.confirmPaymentIntent, { intentId: paymentIntentReference });

        return fetch(`${url}`, fetchOptions)
            .then(response => response.json());
    },

    paymentIntentStatus() {
        return fetch(router.generate(endpoints.paymentIntentStatus, { _format: 'json' }), { credentials: 'include' })
            .then(response => response.json())
            .then(payload => payload);
    },

    confirmWithApplePay(params = {}) {
        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });

        const queryParams = objectToParms(params);

        const fetchOptions = {
            credentials: 'include',
            headers,
            method: 'POST',
            body: queryParams
        };

        return httpClient.post(bookingActionTypes.CONFIRM_WITH_APPLEPAY,
            router.generate(endpoints.confirmWithApplePay), fetchOptions).then(res => res);
    },

    removeVoucher(voucherCode, token, paymentIntentId = '') {
        const postBody = `&_token=${encodeURIComponent(token)}&payment_intent=${encodeURIComponent(paymentIntentId)}`;

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });
        const fetchOptions = {
            credentials: 'include',
            headers,
            method: 'POST',
            body: postBody
        };

        return httpClient.post(bookingActionTypes.REMOVE_VOUCHER,
            router.generate(endpoints.removeVoucher, {
                site_tag: siteTag,
                _smith_locale: smithLocale,
                code: voucherCode
            }), fetchOptions).then(res => res);
    },

    removeProduct(removeProductLink, paymentIntentId = '') {
        const postBody = `&payment_intent=${encodeURIComponent(paymentIntentId)}`;

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });

        const fetchOptions = {
            credentials: 'include',
            headers,
            method: 'POST',
            body: postBody
        };

        return httpClient.post(bookingActionTypes.REMOVE_PRODUCT, removeProductLink, fetchOptions);
    },

    addLoyalty(amount, token, paymentIntentId = '') {
        const postBody = `&loyalty[amount]=${encodeURIComponent(amount)}&_token=${encodeURIComponent(token)}&payment_intent=${encodeURIComponent(paymentIntentId)}`;

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });
        const fetchOptions = {
            credentials: 'include',
            headers,
            method: 'POST',
            body: postBody
        };

        return httpClient.post(bookingActionTypes.INIT_UPDATE_LOYALTY,
            router.generate(endpoints.addLoyalty, {
                site_tag: siteTag,
                _smith_locale: smithLocale
            }), fetchOptions).then(res => res);
    },

    removeLoyalty(token, paymentIntentId = '') {
        const postBody = `&_token=${encodeURIComponent(token)}&payment_intent=${encodeURIComponent(paymentIntentId)}`;

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });
        const fetchOptions = {
            credentials: 'include',
            headers,
            method: 'POST',
            body: postBody
        };

        return httpClient.post(bookingActionTypes.REMOVE_VOUCHER,
            router.generate(endpoints.removeLoyalty, {
                site_tag: siteTag,
                _smith_locale: smithLocale
            }), fetchOptions).then(res => res);
    },

    amazonPaySaveBookingId(params = {}) {
        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });

        const fetchOptions = {
            credentials: 'include',
            headers,
            method: 'POST'
        };

        const routingParams = Object.assign({}, params, {
            site_tag: siteTag,
            _smith_locale: smithLocale
        });

        return httpClient.post(bookingActionTypes.INIT_AMAZONPAY_DATA,
            router.generate(endpoints.amazonPaySaveBookingId, routingParams), fetchOptions).then(res => res);
    },

    removeAmazonSession() {
        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });

        const fetchOptions = {
            credentials: 'include',
            headers,
            method: 'FETCH'
        };

        return httpClient.post(bookingActionTypes.REMOVE_AMAZONPAY_SESSION,
            router.generate(endpoints.removeAmazonPaySession, {
                site_tag: siteTag,
                _smith_locale: smithLocale
            }), fetchOptions).then(res => res);
    },


    confirmOrder(formData) {
        const postBody = buildFormToPost(formData);

        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        };
        const fetchOptions = {
            credentials: 'include',
            headers,
            body: postBody
        };


        if (siteTag !== "mrandmrs") {
            return httpClient.post(bookingActionTypes.INIT_CONFIRM_ORDER,
                router.generate(endpoints.confirmCollectionsOrder, {
                    site_tag: siteTag,
                    _smith_locale: smithLocale
                }), fetchOptions).then(res => res);
        } else {
            return httpClient.post(
                bookingActionTypes.INIT_CONFIRM_ORDER,
                router.generate(endpoints.confirmOrder, {
                    site_tag: siteTag,
                    _smith_locale: smithLocale
                }), fetchOptions).then(res => res);
        }
    },

    confirmPaymentBS2(paymentIntent, currency) {
        const queryParameters = [];

        queryParameters.push(`&payment_intent=${encodeURIComponent(paymentIntent)}`);
        queryParameters.push(`&sale_currency=${encodeURIComponent(currency)}`);
        const postBody = queryParameters.join('');

        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        };
        const fetchOptions = {
            credentials: 'include',
            headers,
            body: postBody
        };

        return httpClient.post(bookingActionTypes.INIT_CONFIRM_PAYMENT,
            router.generate(endpoints.confirmPaymentBS2, {
                site_tag: siteTag,
                _smith_locale: smithLocale
            }), fetchOptions).then(res => res);
    },

    getShippingOptions(countryCode) {
        const routingParams = {
            site_tag: siteTag,
            _smith_locale: smithLocale,
            country: countryCode
        };
        return httpClient.get(bookingActionTypes.SHIPPING_OPTIONS,
            router.generate(endpoints.getCountryShipping, routingParams), { credentials: 'include' }).then(res => res);
    },

    addShipping(id, shippingAddress, paymentIntentId = '') {
        const queryParameters = [];

        queryParameters.push(`&shipping_options=${encodeURIComponent(parseInt(id, 10))}`);
        queryParameters.push(`&shipping_address[line_1]=${encodeURIComponent(shippingAddress.shippingaddress1)}`);
        queryParameters.push(`&shipping_address[name]=${encodeURIComponent(shippingAddress.shippingname)}`);
        queryParameters.push(`&shipping_address[town]=${encodeURIComponent(shippingAddress.shippingcity)}`);
        queryParameters.push(`&shipping_address[county]=${encodeURIComponent(shippingAddress.shippingstate)}`);
        queryParameters.push(`&shipping_address[postcode]=${encodeURIComponent(shippingAddress.shippingpostcode)}`);
        queryParameters.push(`&shipping_address[country]=${encodeURIComponent(shippingAddress.shippingcountry)}`);
        queryParameters.push(`&payment_intent=${encodeURIComponent(paymentIntentId)}`);

        const postBody = queryParameters.join('');

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });

        const fetchOptions = {
            credentials: 'include',
            headers,
            body: postBody
        };

        return httpClient.post(bookingActionTypes.ADD_SHIPPING,
            router.generate(endpoints.addShipping, {
                site_tag: siteTag,
                _smith_locale: smithLocale
            }), fetchOptions)
            .then(res => res)
            .catch((error) => { throw error; });
    },

    removeShipping(paymentIntentId = '') {
        const postBody = `&payment_intent=${encodeURIComponent(paymentIntentId)}`;

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });

        const fetchOptions = {
            credentials: 'include',
            headers,
            body: postBody
        };

        return httpClient.post(bookingActionTypes.REMOVE_SHIPPING,
            router.generate(endpoints.removeShipping, {
                site_tag: siteTag,
                _smith_locale: smithLocale
            }), fetchOptions)
            .then(res => res)
            .catch((error) => { throw error; });
    },

    confirmPayment(paymentIntentId, lineItemId, chargeIds, saveCard) {
        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });
        const queryParameters = [];
        queryParameters.push(`&paymentIntent=${paymentIntentId}`);
        queryParameters.push(`&lineItem=${lineItemId}`);
        queryParameters.push(`&charges[]=${chargeIds}`);
        queryParameters.push(`&updateReferringTransaction=${saveCard}`);
        const postBody = queryParameters.join('');

        const fetchOptions = {
            credentials: 'include',
            method: 'POST',
            headers,
            body: postBody
        };

        const url = router.generate(endpoints.confirmPayment);

        return fetch(`${url}`, fetchOptions)
            .then(response => response.json());
    },

    confirmContributionPayment(formData, paymentDetails, giftListCode) {
        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });

        const params = { giftListCode: parseInt(giftListCode, 10) };

        const postBodyParams = [];
        postBodyParams.push(`&address[address_line_1]=${formData.address.billingaddress1}`);
        postBodyParams.push(`&address[address_line_2]=${formData.address.billingaddress2}`);
        postBodyParams.push(`&address[city]=${formData.address.billingcity}`);
        postBodyParams.push(`&address[country]=${formData.address.billingcountry}`);
        postBodyParams.push(`&address[postcode]=${formData.address.billingpostcode}`);
        postBodyParams.push(`&address[region]=${formData.address.billingregion}`);
        postBodyParams.push(`&address[state]=${formData.address.billingstate}`);
        postBodyParams.push(`&first_name=${formData.firstName}`);
        postBodyParams.push(`&last_name=${formData.lastName}`);
        postBodyParams.push(`&email=${formData.email}`);
        postBodyParams.push(`&currency=${formData.currency}`);
        postBodyParams.push(`&credit_amount=${formData.creditAmount}`);
        postBodyParams.push(`&message=${formData.message}`);
        postBodyParams.push(`&newsletter=${formData.newsletter}`);

        postBodyParams.push(`&payment_details[payment_intent_id]=${paymentDetails.payment_intent_id}`);
        postBodyParams.push(`&payment_details[payment_method_id]=${paymentDetails.payment_method_id}`);
        postBodyParams.push('&payment_details[user_gateway_reference]=');

        const postBody = postBodyParams.join('');

        const fetchOptions = {
            credentials: 'include',
            headers,
            method: 'POST',
            body: postBody
        };

        return fetch(router.generate(endpoints.confirmContributePayment, params), fetchOptions)
            .then((response) => {
                if (!response.ok) {
                    return response;
                }

                return response.json();
            })
            .catch((error) => { throw error; });
    }
};
