import * as actionTypes from '../actions/actionTypes';
import AmazonPayViewModel from '../viewModels/AmazonPayViewModel';

export const amazonPay = (state = new AmazonPayViewModel(), action) => {
    switch (action.type) {
        case actionTypes.INIT_AMAZONPAY_DATA:
            return Object.assign(new AmazonPayViewModel(), state, action.payload);
        default:
            return state;
    }
};
