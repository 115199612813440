export default class AmazonPayViewModel {
    clientId: string;
    merchantId: string;
    buttonType: string;
    color: string;
    
    constructor(
        clientId: string, 
        merchantId:string, 
        buttonType: string = 'PwA', 
        color: string = 'LightGray'
    ) {
        this.clientId = clientId;
        this.merchantId = merchantId;
        this.buttonType = buttonType;
        this.color = color;
    }
}
