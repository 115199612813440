import { combineReducers } from 'redux';
import * as actionTypes from '../actions/actionTypes';

const fieldErrors = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.INIT_UPDATE_FORM_ERRORS:
            return Object.assign({}, state, action.formErrors);
        default:
            return state;
    }
};

const globalErrors = (state = [], action) => {
    switch (action.type) {
        case actionTypes.INIT_GLOBAL_ERRORS:
            return [...action.globalErrors];
        default:
            return state;
    }
};

export default combineReducers({
    fieldErrors,
    globalErrors
});
