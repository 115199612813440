import React, { Component } from 'react';

export default WrappedComponent =>
    class SubmitInput extends Component {
        static displayName = `withSubmitButton(${WrappedComponent.displayName || WrappedComponent.name})`;

        constructor(props, context) {
            super(props, context);

            this.handleEnterSubmit = this.handleEnterSubmit.bind(this);
        }

        handleEnterSubmit(event) {
            if (event.which === 13 || event.keyCode === 13) {
                event.preventDefault();
                this.button.click();
                return false;
            }
            return true;
        }

        render() {
            const { inputProps, buttonProps: { onclick, classes, value, disabled } } = this.props;
            return (
                <div className="submitInput">
                    <WrappedComponent {...inputProps} keydown={this.handleEnterSubmit} />
                    <button
                        ref={(button) => { this.button = button; }}
                        onClick={onclick}
                        disabled={disabled}
                        className={classes}
                    >
                        {value}
                    </button>
                </div>
            );
        }
    };
