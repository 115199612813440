export default class User {
    constructor(
        firstName,
        lastName,
        phoneNumbers,
        emails,
        addresses,
        defaultEmail,
        defaultPhoneNumber,
        defaultMailAddress,
        defaultShipAddress,
        Loyalty
    ) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumbers = phoneNumbers;
        this.emails = emails;
        this.addresses = addresses;
        this.defaultEmail = defaultEmail;
        this.defaultPhoneNumber = defaultPhoneNumber;
        this.defaultMailAddress = defaultMailAddress;
        this.defaultShipAddress = defaultShipAddress;
        this.Loyalty = Loyalty;
    }
}
