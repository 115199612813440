import React, { Component } from 'react';
import InputTypes from '../../common/components/InputTypes';
import { SelectBox } from '../../common/components/SelectBox';
import api from '../../common/services/api';
import getFromDataLayer from '../../utilities/getFromDataLayer';
// import { loadJS } from '../../utilities/loadJs';
// import { reCaptchaKey, reCaptchaUrl } from '../../common/constants/thirdPartyJs';
import AddressFields from '../../area/booking/components/AddressFields';
import PostCodeLookup from '../../area/booking/components/PostCodeLookup';
import addressFactory from '../../area/booking/viewModels/addressFactory';

export default class DataCaptureForm extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            formFields: {
                country: 'GB',
                firstName: '',
                lastName: '',
                email: '',
                slug: '',
                'Optins[mmsNewsLetter]': false,
                userAddress: {
                    postalcountry: 'GB'
                }
            },
            countries: [],
            submissionError: '',
            isProcessing: false,
            region: 'EMEA',
            showFullAddress: false,
            ctaText: 'submit',
            formMessage: '',
            optins: [],
            // isRecaptchaValid: false,
            // reCaptchaResponse: '',
            showAddressFields: true,
            sendPrintedVersion: true,
            states: [],
            mainContent: '',
        };

        // this.recpatCaptchInstance = null;

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.showAddressFields = this.showAddressFields.bind(this);
        this.handleAddressSelection = this.handleAddressSelection.bind(this);
        this.onAddressChangeHandler = this.onAddressChangeHandler.bind(this);
        // this.handleSendPrintedVersion = this.handleSendPrintedVersion.bind(this);
    }

    componentDidMount() {
        const { formFields, formFields: { userAddress } } = this.state;
        const { formEndpoint, smithNewsletter } = this.props;

        const user = getFromDataLayer(window.dataLayer, 'user');
        const optInForMarketing = smithNewsletter ? smithNewsletter : ['US', 'AR'].includes(user.sessionCountry);

        // window.reCaptchaOnloadCallback = () => {
        //     this.recpatCaptchInstance = window.grecaptcha.render(this.recaptchaElement, {
        //         sitekey: reCaptchaKey
        //     });
        // };

        fetch(formEndpoint).then(response => response.json()).then((response) => {
            return api.getCountries().then((countries) => {
                this.setState({
                    countries,
                    formFields: Object.assign({}, formFields, {
                        firstName: user.firstname || '',
                        lastName: user.lastname || '',
                        email: user.email || '',
                        country: user.sessionCountry === 'UK' || response.slug === 'honeymoon' ? 'GB' : user.sessionCountry,
                        'Optins[mmsNewsLetter]': optInForMarketing,
                        slug: response.slug,
                        userAddress: Object.assign({}, userAddress, { postalcountry: user.sessionCountry })
                    }),
                    region: user.sessionRegion,
                    showFullAddress: response.fullAddress,
                    ctaText: response.ctaText,
                    formMessage: response.mainContent,
                    optins: response.optins,
                    mainContent: response.mainContent
                });

                // loadJS(reCaptchaUrl, () => {
                //     if (this.recpatCaptchInstance === null && window.grecaptcha.render) {
                //         this.recpatCaptchInstance = window.grecaptcha.render(this.recaptchaElement, {
                //             sitekey: reCaptchaKey
                //         });
                //     }
                // });
            });
        }).then(() => {
            const code = user.sessionCountry;
            this.getStateList(code);
        });
    }

    getStateList(countryCode) {
        if (countryCode.toLowerCase() === 'us' || countryCode.toLowerCase() === 'ca' || countryCode.toLowerCase() === 'au') {
            api.getStateList(countryCode).then((states) => {
                if (states !== false) {
                    const stateList = Object.keys(states).reduce((acc, cur) => {
                        acc.push([cur, states[cur]]);
                        return acc;
                    }, []);
                    this.setState({
                        states: stateList
                    });
                } else {
                    this.setState({ states: [] });
                }
            });
        } else {
            this.setState({states: []});
        }
    }

    // componentWillUnmount() {
    //     window.grecaptcha.reset(this.recpatCaptchInstance);
    // }

    onChangeHandler(event) {
        const field = event.target.name;
        const { formFields: { userAddress }, formFields } = this.state;
        const { smithNewsletter } = this.props;

        if (field === 'country') {
            const optInForMarketing = smithNewsletter ? smithNewsletter : ['US', 'AR'].includes(event.target.value);

            this.getStateList(event.target.value);
            return this.setState({
                formFields: {
                    ...formFields,
                    'Optins[mmsNewsLetter]': optInForMarketing,
                    [field]: event.target.value,
                    userAddress: {
                        ...userAddress,
                        'postalcountry': event.target.value,
                    }
                }
            });
        }

        this.setState({
            formFields: Object.assign({}, formFields, {
                [field]: event.target.value
            })
        });
    }

    onAddressChangeHandler(event) {
        const { name, value } = event.target;
        const { formFields: { userAddress, country }, formFields } = this.state;

        if (name === 'postalpostcode' && country === 'US') {
            const val = event.target.value.replace(/[^0-9+]+/g, '');
            return  this.setState({
                formFields: {
                    ...formFields,
                    userAddress: {
                        ...userAddress,
                        [name]: val
                    }
                }
            });
        }

        this.setState({
            formFields: {
                ...formFields,
                userAddress: {
                    ...userAddress,
                    [name]: value
                }
            }
        });
    }

    // handleSendPrintedVersion(event) {
    //     const { name: field, checked } = event.target;
    //     const { setSendPost } = this.props;
    //     const { formFields } = this.state;

    //     this.setState({
    //         sendPrintedVersion: event.target.checked,
    //         formFields: Object.assign({}, formFields, { [field]: checked })
    //     }, () => {
    //         setSendPost();
    //     });
    // }

    handleCheckboxChange(event) {
        const { name: field, checked } = event.target;
        const { formFields } = this.state;
        const { onBlurValidation } = this.props;
        event.persist();

        this.setState({
            formFields: Object.assign({}, formFields, { [field]: checked })
        }, () => {
            onBlurValidation(event);
        });
    }

    submitForm(event) {
        event.preventDefault();

        const { validateForm, setConfirmation } = this.props;
        const { formFields } = this.state;

        const form = event.target.form;

        validateForm(form).then((errors) => {

            // const captchaResponse = window.grecaptcha.getResponse(this.recpatCaptchInstance);

            const numberOfErrors = Object.keys(errors).reduce((acc, curr) => {
                if (errors[curr] !== '') {
                    acc += 1;
                }
                return acc;
            }, 0);

            // if (captchaResponse === '') {
            //     this.setState({
            //         submissionError: 'Please confirm captcha box'
            //     });
            // }

            if (numberOfErrors === 0) {
                this.setState({
                    isProcessing: true
                });

                // const formWithCaptcha = Object.assign({}, formFields, { 'g-recaptcha-response': captchaResponse });
                const formWithSendToPrint = Object.assign({}, formFields, { sendPrintedVersion: true });
                const formData = formFields.slug === 'honeymoon' ? formWithSendToPrint : formFields;
                api.dataCapture(formData, formFields.slug).then((response) => {
                    if (response && response.error) {
                        this.setState({
                            submissionError: response.error,
                            isProcessing: false
                        });
                    } else {
                        setConfirmation(response.data.successContent);
                    }
                }).catch(() => {
                    this.setState({
                        submissionError: 'Something went wrong. Please check your details and try again.',
                        isProcessing: false
                    });
                });
            }
        });
    }

    showAddressFields() {
        this.setState({ showAddressFields: true });
    }

    handleAddressSelection(event) {
        const addressLookup = event.target.value;
        const formatAddress = JSON.parse(addressLookup);
        const lookupAddress = addressFactory(formatAddress);
        const {
            formFields,
            formFields: { country }
        } = this.state;

        const newAddress = {
            postalname: lookupAddress.name,
            postaladdress1: lookupAddress.address1,
            postaladdress2: lookupAddress.address2,
            postalcity: lookupAddress.city,
            postalpostcode: lookupAddress.postcode,
            postalstate: lookupAddress.state,
            postalcountry: country
        };

        this.setState({
            formFields: {
                ...formFields,
                userAddress: {
                    ...newAddress
                }
            },
            showAddressFields: true
        });
    }

    renderOptinsOptions() {
        const { optins } = this.state;
        const { formErrors } = this.props;

        return optins.map(optin => (<InputTypes
            layout="inverted"
            key={optin.slug}
            type="checkbox"
            checked={this.state.formFields[`Optins[${optin.slug}]`]}
            name={`Optins[${optin.slug}]`}
            label={optin.text}
            placeHolder={optin.text}
            onChange={this.handleCheckboxChange}
            error={formErrors[`Optins[${optin.slug}]`]}
            isRequired={optin.mandatory}
        />));
    }

    render() {
        const {
            formFields: {
                country, firstName, lastName, email, userAddress, slug
            },
            countries,
            submissionError,
            isProcessing,
            region,
            ctaText,
            formMessage,
            showAddressFields,
            sendPrintedVersion,
            states,
            mainContent,
            optins
        } = this.state;

        const { onBlurValidation, formErrors, smithNewsletter } = this.props;
        const awardAmount = region === 'EMEA' ? '£1,000' : '$1,300';

        const postalAddressInputRefs = {
            postaladdress1: (input) => { this.postaladdress1 = input; },
            postalcity: (input) => { this.postalcity = input; },
            postalpostcode: (input) => { this.postalpostcode = input; },
            postCodeLookup: (input) => { this.postCodeLookup = input; }
        };

        const postcodeLabel = ['US', 'CA'].indexOf(country) !== -1 ? 'Zip code' : 'Postcode';
        const showStateDropdown = country === 'AU' ||  country === 'CA';

        const titleClassName = smithNewsletter ? 'c-dataCapture__title c-dataCapture__title--newsletter' : 'c-dataCapture__title';
        const copyClassName = smithNewsletter ? 'c-dataCapture__copy c-dataCapture__copy--newsletter' : 'c-dataCapture__copy';

        const {
            [`postalpostcode`]: postcode,
            [`postalstate`]: stateCode
        } = userAddress;

        return (
            <article className="c-dataCapture clearfix">
                <header className="c-dataCapture__header">
                    <img className="c-dataCapture__backgroundImg" src={mainContent.imageUrl} alt="" />
                    { mainContent.imageUrl2 && (
                        <img className="c-dataCapture__backgroundImg" src={mainContent.imageUrl2} alt="" />
                    )}
                </header>
                <section className="c-dataCapture__content">
                    <h2 className={titleClassName} dangerouslySetInnerHTML={{ __html: mainContent.title }} />
                    <div className={copyClassName} dangerouslySetInnerHTML={{ __html: mainContent.copy }} />

                    <form className="c-dataCapture__form">
                        <div className="flex-column-s flex-row-top-justify-m flex-row-top-justify-l">
                            <div className="p-field">
                                <InputTypes
                                    name="firstName"
                                    value={firstName}
                                    isRequired={true}
                                    placeHolder="First name"
                                    label="First name"
                                    onChange={this.onChangeHandler}
                                    onBlur={onBlurValidation}
                                    error={formErrors.firstName}
                                />
                            </div>
                            <div className="p-field">
                                <InputTypes
                                    name="lastName"
                                    value={lastName}
                                    isRequired={true}
                                    placeHolder="Last name"
                                    label="Last name"
                                    onChange={this.onChangeHandler}
                                    onBlur={onBlurValidation}
                                    error={formErrors.lastName}
                                />
                            </div>
                        </div>
                        <div className="p-email">
                            <InputTypes
                                name="email"
                                value={email}
                                isRequired={true}
                                placeHolder="Email address"
                                label="Email address"
                                pattern="(@)(.+)$"
                                onChange={this.onChangeHandler}
                                onBlur={onBlurValidation}
                                error={formErrors.email}
                            />
                        </div>
                        <div className="flex-column-s flex-row-top-justify-m flex-row-top-justify-l">
                            <div className="p-field">
                                { slug === 'honeymoon' ?
                                    <SelectBox
                                        name="country"
                                        value={country}
                                        options={[['GB', 'United Kingdom']]}
                                        valueIndex={0}
                                        placeholder="false"
                                    />
                                :
                                    <SelectBox
                                        name="country"
                                        value={country}
                                        onChange={this.onChangeHandler}
                                        options={countries}
                                        valueIndex={0}
                                        placeholder="false"
                                    />
                                }
                            </div>
                            <div className="p-field">
                                {country === 'US' && !showAddressFields && (
                                    <InputTypes
                                        inputRef={postalAddressInputRefs["postalpostcode"]}
                                        name="postalpostcode"
                                        label={postcodeLabel}
                                        onChange={this.onAddressChangeHandler}
                                        placeHolder={postcodeLabel}
                                        isRequired={true}
                                        value={postcode}
                                        onBlur={onBlurValidation}
                                        error={formErrors["postalpostcode"]}
                                        autocomplete="postalpostcode postal-code"
                                    />
                                )}
                                {states.length > 0 && showStateDropdown && (
                                    <SelectBox
                                        name="postalstate"
                                        options={states}
                                        valueIndex={0}
                                        onChange={this.onAddressChangeHandler}
                                        value={stateCode}
                                        isRequired={true}
                                        placeholder="State"
                                        error={formErrors["postalstate"]}
                                        inputRef={postalAddressInputRefs["postalstate"]}
                                        autocomplete="postal address-level1"
                                    />
                                )}
                            </div>
                        </div>
                        {/* {!sendPrintedVersion && slug === 'honeymoon' &&
                        <div className="sendPrintedVersionNasa clearfix">
                            <p>{mainContent.post}</p>
                            <InputTypes
                                layout="inverted"
                                type="checkbox"
                                checked={this.state.sendPrintedVersion}
                                name="sendPrintedVersion"
                                label="Send me a printed version"
                                placeHolder={mainContent.postCheckbox}
                                onChange={this.handleSendPrintedVersion}
                                error={formErrors.sendPrintedVersion}
                                isRequired={false}
                            />
                        </div>
                        } */}
                        {/* {!showAddressFields && region !== 'NASA' && sendPrintedVersion && slug === 'honeymoon' &&
                        <div className="p-postCodeSearch">
                            <PostCodeLookup
                                name="selectPostalAddress"
                                country={country}
                                enterAddressManually={this.showAddressFields}
                                onChange={this.handleAddressSelection}
                                onCountrySelection={this.onAddressChangeHandler}
                                countrySelectName="postalcountry"
                                countries={countries}
                                errors={formErrors}
                                updateErrors={() => {}}
                                inputRefs={postalAddressInputRefs}
                                validate={onBlurValidation}
                                isRequired={false}
                            />
                        </div>
                        } */}
                        {showAddressFields && sendPrintedVersion && slug === 'honeymoon' &&
                        <div className="addressFields">
                            <AddressFields
                                formPrefix="postal"
                                inputRefs={postalAddressInputRefs}
                                validate={onBlurValidation}
                                countries={countries}
                                userAddress={userAddress}
                                onChange={this.onAddressChangeHandler}
                                selectChange={this.onAddressChangeHandler}
                                onBlur={onBlurValidation}
                                errors={formErrors}
                            />
                        </div>
                        }
                        { optins.length > 0 && (
                            <div className="c-dataCapture__optins">
                                {this.renderOptinsOptions()}
                            </div>
                        )}
                        {/* <div className="c-dataCapture__captcha" ref={(div) => { this.recaptchaElement = div; }} /> */}
                        <div className="c-dataCapture__ctaWrapper">
                            <button type="button" className="c-dataCapture__cta" disabled={isProcessing} onClick={this.submitForm}>{ctaText}</button>
                        </div>
                        {submissionError !== '' && (
                            <p className="c-form__error c-form__error--center">{submissionError}</p>
                        )}
                        <span className="c-dataCapture__requiredMessage">
                            <sup>*</sup>
                            Required fields
                        </span>
                    </form>
                </section>
            </article>
        );
    }
}
