import * as actionTypes from '../actions/actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case actionTypes.INIT_CSRF_TOKEN:
            return Object.assign({}, state, action.payload);
        case actionTypes.INIT_UPDATE_LOYALTY:
        case actionTypes.REMOVE_LOYALTY:
        case actionTypes.REMOVE_VOUCHER:
        case actionTypes.ADD_GIFT_VOUCHER_INITIAL:
        case actionTypes.ADD_GIFT_VOUCHER:
            return Object.assign({}, state, action.payload.csrf_tokens);
        default:
            return state;
    }
};
