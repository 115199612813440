import * as actionTypes from '../actions/actionTypes';

export const bookingSummaryItems = (state = [], action) => {
    switch (action.type) {
        case actionTypes.INIT_BOOKING_SUMMARY_ITEMS:
            return [...action.payload];
        case actionTypes.REMOVE_BOOKING_SUMMARY_ITEM:
            return [...action.payload];
        default:
            return state;
    }
};

export const getTermsContent = (state = [], action) => {
    switch (action.type) {
        case actionTypes.GET_TERMS_CONTENT:
            return action.payload;
        default:
            return state;
    }
};

export const products = (state = [], action) => {
    switch (action.type) {
        case actionTypes.INIT_PRODUCT_ITEMS:
            return [...action.payload];
        case actionTypes.REMOVE_PRODUCT:
            return state.filter(product => product.key !== action.productKey);
        default:
            return state;
    }
};

export const longestNight = (state = [], action) => {
    switch (action.type) {
        case actionTypes.UPDATE_NIGHTS:
            return action.payload;
        default:
            return state;
    }
};
