import * as actionTypes from '../actions/actionTypes';

export default (state = [], action) => {
    switch (action.type) {
        case actionTypes.ADD_GIFT_VOUCHER:
            return [...action.payload.vouchers];
        case actionTypes.ADD_GIFT_VOUCHER_INITIAL:
            return [...action.payload];
        case actionTypes.REMOVE_VOUCHER:
            return state.filter(voucher => voucher.code !== action.voucherCode);
        default:
            return state;
    }
};
